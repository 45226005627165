@charset "UTF-8";

@media screen and (max-width: 768px) {
    #navbar_wrapper {
        .navbar-default {
            .navbar-brand {
                margin-left: 0;
            }
        }
    }

    .app__body {
        .MenuWrapper {
            .MenuItem__divider {
                margin: 0;
                border: none;
            }
        }
        .modal {
            .settings-modal {
                &:not(.settings-modal--tabless) {
                    &:not(.display--content) {
                        .modal-content {
                            background: var(--sidebar-bg);
                        }
                    }
                }
            }
        }
    }
    .post-right__container {
        height: 100%;

        .post {
            &.post--thread {
                .post-pre-header__icons-container {
                    padding-right: 12px; // If the padding of post__img changes, this needs to be adjusted accordingly
                    width: 60px; // If the width of post__img changes, this needs to be adjusted accordingly
                    margin-left: 0; // if left margin of post__content changes, this needs to be adjusted accordingly
                }
            }
        }
    }

    .line-switch__toggle {
        margin: 8px 0;
    }


    .backstage-list__item {

        .item-actions,
        .actions {
            margin: 10px 0;
        }
    }


    .integration__icon {
        &.integration-list__icon {
            position: relative;
            top: 0;
            margin: 0 20px 15px;
            width: 50px;
            height: 50px;
        }
    }

    .emoji-picker {
        // !important is used to overide inline styles
        // used on larger screens
        top: 0 !important;
        left: 0 !important;
        right: auto !important;
        bottom: auto !important;
        width: 100%;
        height: 100%;
        border-radius: 0;
        z-index: 1070;

        >ul {
            margin-top: 66px;
        }

        &.bottom {
            margin-top: 0;
        }

        .search-grid-container {
            height: calc(100vh - 170px);
        }
    }

    .emoji-picker__inner {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 100px);

        .emoji-picker--single & {
            height: calc(100vh - 55px);
        }

        >* {
            flex-grow: 0;
        }
    }

    .emoji-picker__header,
    .modal-body .emoji-picker .emoji-picker__header {
        display: block;
    }

    .emoji-picker__categories {
        .emoji-picker__category {
            width: 30px;
            height: 30px;
            padding-top: 4px;
        }
    }

    .emoji-picker__items {
        // !important is used to overide inline styles
        // used on larger screens
        height: auto !important;
        flex-grow: 1;

        .emoji-picker__category-header {
            font-size: 14px;
            padding-top: 8px;
        }
    }

    .emoji-picker__item {
        margin: calc(100% / 45);
        padding: 0;
        padding-top: calc(100% / 15);
        width: calc(100% / 15);
        height: auto;

        > div {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 30px;
            height: 30px;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .emoji-picker__preview {
        width: 100%;
        height: 60px;
        padding: 10px 10px 0;
    }

    .MenuWrapper {
        .Menu__content {
            &.dropdown-menu {
                max-width: 100%;
            }

            &.mobile-channel-header-dropdown-enter {
                transform: translateY(100%);
            }

            &.mobile-channel-header-dropdown-enter-active {
                transform: translateY(0%);
                transition: transform .35s ease-in;
            }

            &.mobile-channel-header-dropdown-enter-done {
                transform: translateY(0%);
            }

            &.mobile-channel-header-dropdown-exit {
                transform: translateY(100%);
                transition: transform .35s ease-in;
            }
        }
    }

    .mobile-main-menu {
        .Menu__content {
            &.dropdown-menu {
                width: 100%;
                position: relative;
                background: transparent;
                padding-left: 0;
                list-style: none;
                margin-bottom: 0;
                max-height: inherit;
                max-width: inherit;
                border: 0;
                padding-top: 0;
                box-shadow: none;
                color: inherit;

                button,
                a,
                a:hover {
                    opacity: .8;
                }

                .MenuItem {

                    >button,
                    >a {
                        height: auto;
                        padding: 3px 16px;
                        font-size: 15px;
                        line-height: 32px;
                        width: 100%;
                        text-align: left;
                    }
                }

                .MenuGroup {
                    &.menu-divider {
                        background: var(--center-channel-bg);
                    }
                }
            }
        }
    }

    .post__header, .GlobalThreads {
        .dropdown-menu {
            >li {
                >div {
                    border-top-color: rgba(var(--center-channel-color-rgb), 0.08) !important;

                    .SubMenu__icon {
                        padding: 0 5px 0 8px !important;
                    }
                }

                >div,
                >button {
                    @include border-radius(0);
                    border-top: 1px solid rgba(var(--center-channel-color-rgb), 0.12);
                    padding: 15px 20px;
                    height: auto;
                }

                &:first-child {

                    div,
                    button {
                        border: none;
                    }
                }
            }
        }
    }

    .app__body {
        .post, .SidebarMenu, .AddChannelDropdown, .GlobalThreads {
            .Menu {
                @include border-radius(0);
                border: 0;
                height: 100%;
                left: 0;
                position: fixed;
                top: 0;
                width: 100%;
                z-index: 9999;
                display: flex;
                margin: 0;
                padding: 6em 2em 4em;
                background: transparent;
                flex-wrap: wrap;
                align-items: center;
                overflow: auto;

                .Menu__content {
                    width: 100%;
                    max-height: inherit;
                    max-width: inherit;
                    position: relative;
                    top: 0;
                    padding: 0;
                }

                &:after {
                    background: alpha-color($black, .3);
                    content: '';
                    height: 100%;
                    left: 0;
                    position: fixed;
                    top: 0;
                    width: 100%;
                }

                .MenuItem {
                    z-index: 9999;
                    background: var(--center-channel-bg);

                    &:last-child button {
                        border-bottom: 0;
                    }
                }
            }
        }
    }

    .integration-option {
        flex: 0 0 100%;
        margin: 0 0 30px;
    }

    .channel-header,
    .GlobalThreads .Header  {
        .channel-header__icon {
            display: none;
        }
    }

    .member-role .member-menu,
    .member-drop .member-menu {
        right: 0;
        top: 30px;
    }

    .post-code {
        word-wrap: normal;
    }

    .table-responsive {
        border: none;
    }

    .multi-select__container {
        .btn {
            display: block;
            min-width: 50px;
        }

        .Select-value-label {
            max-width: 190px;
            text-overflow: ellipsis;
        }
    }

    .more-modal__list {
        .more-modal__actions--round {
            @include opacity(.5);
        }
    }

    .post-create__container {
        z-index: 2;

        form {
            padding: 0;
        }

        .post-create-body {
            padding: 0;

            .send-button {
                display: block;
            }

            .custom-textarea {
                &.custom-textarea--emoji-picker {
                    max-height: 162px;
                    padding-right: 135px;
                }
            }

            textarea {
                .app-content & {
                    border-left: none;
                    border-right: none;
                }
            }

            .post-body__actions {
                .Menu.dropdown-menu {
                    max-height: 80vh;
                }
            }
        }

        .msg-typing {
            display: none;
        }
    }

    .post-create-footer {
        padding: 0 16px;

        .post-error {
            position: relative;
            top: 0;
            margin: 8px 0;
        }

        .help__text {
            display: none;
        }
    }

    .suggestion-list__content {
        max-height: 145px;
    }

    .filtered-user-list {
        .filter-button {
            .btn {
                width: 100%;
            }
        }
    }

    .settings-modal {
        .modal-body {
            overflow: auto;
        }
    }

    #header-popover {
        @include single-transition(all, .35s, ease);
        @include translate3d(0, 100%, 0);
        @include box-shadow(none);
        background: alpha-color($black, .9);
        border: none;
        height: calc(100% - 50px);
        max-width: 100%;
        position: fixed;
        top: 40px !important;
        width: 100%;

        &.in {
            @include translate3d(0, 0, 0);
        }

        a {
            color: #0091ff;
        }

        .arrow {
            display: none;
        }

        .popover-content {
            color: $white;
            font-size: 15px;
            padding: 15px 20px 100px;

            >div {
                &:first-child {
                    -webkit-overflow-scrolling: touch;
                    height: calc(100vh - 150px);
                    overflow: auto;
                }
            }
        }

        .close {
            @include border-radius(50%);
            border: 1px solid $white;
            bottom: 25px;
            color: $white;
            display: block;
            font-family: 'Open Sans', sans-serif;
            font-size: 23px;
            font-weight: 200;
            height: 30px;
            left: 50%;
            line-height: 0;
            margin-left: -15px;
            opacity: 1;
            padding-top: 13px;
            position: fixed;
            text-align: center;
            text-shadow: none;
            width: 30px;
        }
    }

    .app__body {
        .edit-modal-body {
            .custom-textarea {
                max-height: 30vh;
            }
        }
    }

    .video-div {
        &.embed-responsive-item {
            iframe {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }

    .tutorial-steps__container {
        .tutorial__content {
            .tutorial__steps {
                margin-top: 15%;
                margin-bottom: 15%;
                max-height: 70%;
                padding-bottom: 0;
            }
        }
    }

    .prompt {
        .prompt__heading {
            display: block;

            >div {
                &:first-child {
                    display: block;
                    margin: 0 0 1em;
                }
            }
        }
    }

    .post-code__language {
        @include opacity(1);
        @include transition(none);
        display: block;
        position: relative;
    }

    .backstage-form__footer {
        .has-error {
            float: none !important;
            max-width: 100%;
            margin-bottom: 16px;
        }
    }

    .backstage-filters {
        display: block;

        .backstage-filter__search {
            margin: 10px 0;
            width: 100%;
        }
    }

    .compliance-panel,
    .audit-panel {
        .row {
            >.form-group {
                padding-left: 15px;
            }
        }
    }

    .user-popover {
        cursor: pointer;
        display: inline-block;
    }

    .signup-team__container {
        font-size: 1em;
    }

    .sidebar--left .team__header .Avatar,
    #SidebarContainer .team__header .Avatar,
    .sidebar--menu .team__header .Avatar {
        display: none;
    }

    .channel-intro {
        margin: 0 0 35px;
    }

    .post {
        &:not(.post--compact) {
            .Badge {
                left: 6px;
                top: 31px;
            }
        }

        &.post--system {
            .post__header {
                .post-menu {
                    min-width: 45px;
                }
            }
        }

        .post-add-reaction {
            .Reaction {
                opacity: 1;
                visibility: visible;
            }
        }

        .browser--ie & {
            .post__header {
                .post-menu {
                    .dropdown+div {
                        display: none;
                    }
                }
            }
        }

        .post-menu__item--reactions {
            display: none;
        }

        .post__dropdown {
            display: inline-block;
            height: 28px;
            line-height: 21px;
            text-align: center;
            text-decoration: none;
            visibility: visible;
            width: 28px;

            &:after {
                content: '...';
                font-size: 20px;
                top: -3px;
            }
        }

        .post__remove {
            margin-right: 10px;
            visibility: visible;
        }

        &.post--compact {
            padding-left: 1em;

            .post__img {
                padding-top: 0;

                .post-right__container & {
                    padding-top: 2px;
                }
            }

            &.same--root {
                &.same--user {
                    padding-left: 1em;

                    .post__header {
                        height: auto;
                    }
                }
            }

            .post-message--overflow {
                @include clearfix;
            }

            .status-wrapper {
                &:after {
                    bottom: 3px;

                    .sidebar--right & {
                        bottom: 0;
                    }
                }
            }

            blockquote {
                margin-top: 0;
            }
        }

        .post-pre-header {
            .post-pre-header__icons-container {
                padding-right: 8px; // If the padding of post__img changes, this needs to be adjusted accordingly
                width: 40px; // If the width of post__img changes, this needs to be adjusted accordingly
                margin-left: 0;

                .icon--post-pre-header {
                    &:nth-of-type(even) {
                        margin-left: 8px;
                    }
                }
            }
        }

        .post__content {
            padding: 0 10px 0 0;
        }

        &.current--user {
            &.post--comment {
                .post__header {
                    margin-bottom: 3px;
                }
            }
        }

        .post__header {
            margin-bottom: 0;
            padding-right: 70px;

            .post-menu {
                min-width: 45px;
                top: -6px;
                z-index: auto;

                >div {
                    width: auto;
                }

                .post-menu__item--reactions {
                    display: none;
                }
            }

            .col__name {
                .user-popover {
                    max-width: 130px;
                }
            }
        }

        .comment-icon__container {
            display: none;
            visibility: visible;

            .sidebar--right & {
                display: inline-block;
            }

            &.icon--show {
                display: inline-block;
            }
        }

        .post-list__content & {
            &:hover {
                background: transparent;
            }

            .comment-icon__container {
                visibility: visible;
            }
        }

        .dropdown,
        .post__reply {
            visibility: visible;
        }

        .post__body {
            width: 100%;
        }

        .post__reply {
            float: right;
            margin-right: 20px;

            svg {
                top: 1px;
            }
        }

        &.post--comment {
            .post__body {
                margin-top: 5px;
                padding: 0 0 0 7px;
            }
        }

        &.other--root {
            &.post--comment {
                .post__header {
                    .post-menu {
                        top: -6px;
                    }
                }
            }

            .post__reply {
                &.post__reply--hide {
                    visibility: hidden;
                }
            }
        }

        &.current--user {
            .post__link {
                margin: 0 0 8px;
            }
        }

        .star-icon__container {
            left: auto;
            position: relative;
            top: auto;

            &:not(.visible) {
                display: none;
            }
        }

        &.same--root {
            .star-icon__container {
                left: auto;
                position: relative;
                top: auto;
            }

            &.same--user {
                &.post--compact {
                    .status-wrapper {
                        &:after {
                            bottom: -2px;
                        }
                    }
                }

                .post__img {
                    img {
                        display: block;
                    }
                }

                .post__header {
                    height: auto;
                    margin-top: 5px;

                    .col__name {
                        display: inline-block;
                    }
                }
            }
        }

        .post__img {
            width: 40px; // if this changes, the width of post-pre-header__icons-container needs to be adjusted accordingly

            img {
                height: 32px;
                width: 32px;
            }
        }
    }

    .form-control {
        &.min-height {
            min-height: 100px;
        }
    }

    .img-div {
        max-width: 100%;
    }

    .tip-div {
        left: 15px;
        right: auto;
    }

    .tip-overlay {
        &.tip-overlay--chat {
            margin-left: 10px;

            .arrow {
                left: 32px;
            }
        }
    }

    .file-details__container {
        display: block;

        .file-details__preview {
            border-bottom: 1px solid #dddddd;
            border-right: none;
            display: block;
            height: 150px;
            width: 100%;

            img {
                height: 64px;
                width: 64px;
            }
        }

        .file-details {
            height: auto;
            width: 100%;
        }
    }

    .search-help-popover {
        left: 0;
        max-width: 100%;
    }

    .modal-direct-channels,
    .more-channel__modal {
        .member-count {
            display: block;
            float: none;
            margin-top: 10px;
        }
    }

    .file-overlay {
        font-size: em(18px);

        &.center-file-overlay {
            .overlay__indent {
                margin-left: 0;
            }
        }

        .overlay__circle {
            height: 300px;
            margin: -150px 0 0 -150px;
            width: 300px;
        }

        .overlay__files {
            margin: 60px auto 15px;
            width: 150px;
        }
    }

    .post-list__timestamp {
        display: block;
    }

    .signup-team__container {
        font-size: .9em;
        margin-bottom: 30px;
        padding: 60px 10px 0;

        .signup-team__name {
            font-size: 2em;
        }

        .btn.btn-full {
            padding-left: 10px;
        }

        .btn {

            .icon,
            .fa {
                margin-right: 6px;
            }
        }
    }

    .app__body {
        .row--invite {
            .col-sm-6 {
                &:first-child {
                    padding-right: 15px;
                }
            }
        }

        .modal {
            .modal--scroll {
                .modal-body {
                    max-height: calc(100vh - 62px);
                    overflow: auto;
                }
            }

            .nav-pills {
                >li {
                    &.active {
                        button {
                            background: transparent;

                            &:before {
                                display: none;
                            }
                        }
                    }

                    button {
                        border-bottom: 1px solid;
                        padding: 15px;
                    }
                }
            }

            .info__label {
                padding-bottom: 5px;
                text-align: left;
            }

            .modal-header {
                .modal-action {
                    margin-top: 10px;
                }

                .close {
                    overflow: hidden;
                    font-size: 27px;
                    font-weight: normal;
                    margin-top: -2px;
                }

                .modal-title {
                    float: none;
                    max-width: 90%;
                }

                .btn {
                    &.btn-primary {
                        display: block;
                        float: none;
                        margin: 10px 0 6px;
                        width: 100%;
                    }
                }
            }

            .settings-modal {
                .modal-body {
                    min-height: 100%;
                }

                .nav-pills {
                    >li {
                        &:hover {
                            a {
                                background: transparent !important;
                            }
                        }
                    }
                }

                &.display--content {
                    .modal-header {
                        display: none;
                    }

                    .settings-table {
                        display: block;

                        .settings-content {
                            .section-min__title {
                                padding-right: 15px;
                            }

                            .section-min__edit {
                                position: relative;
                                right: 0;
                                text-align: left;
                                top: 0;
                            }

                            .appearance-section {
                                .theme-elements {
                                    .element {
                                        margin-right: 10px;

                                        &:nth-child(2n) {
                                            margin-right: 10px;
                                        }
                                    }
                                }
                            }

                            &.minimize-settings {
                                display: block;
                            }

                            .section-min:hover {
                                background: none;
                            }

                            .no-padding--left {
                                padding-left: 15px;
                            }
                        }

                        .settings-links {
                            display: none;
                        }

                        .modal-header {
                            display: block;
                            position: absolute;
                            top: 0;
                            width: 100%;
                            z-index: 5;

                            .modal-title {
                                max-width: 100%;
                                padding: 0 40px;
                                text-align: center;
                                width: 100%;

                                >span {
                                    display: block;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }
                        }

                        .user-settings {
                            .tab-header {
                                display: none;
                            }

                            .divider-dark.first {
                                display: none;
                            }
                        }
                    }
                }

                .settings-table {
                    padding: 0;

                    .nav {
                        position: relative;
                        top: auto;
                        width: 100%;
                        margin: 0;

                        &.position--top {
                            top: auto;
                        }
                    }

                    .settings-content {
                        .section-min__edit {
                            text-align: left;

                            button {
                                text-align: left;
                                width: 100%;
                            }

                            .fa {
                                display: inline-block;
                            }
                        }

                        &.minimize-settings {
                            display: none;
                            padding: 0;

                            .user-settings {
                                padding: 70px 20px 30px;
                            }
                        }

                        .section-min:hover {
                            background: none !important;
                        }
                    }

                    .nav {
                        >li {
                            >a {
                                font-size: 1.1em;
                                line-height: 2.7;

                                .icon {
                                    margin: 0 7px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .file-preview__container {
        margin: 10px 0 5px;
    }

    .file-preview {
        margin-top: 0;
    }

    // Since system console is not responsive we're overriding bootstrap styles for it
    .admin-sidebar {
        .navbar-nav {
            margin-top: 0;

            >li {
                float: left;
            }

            .dropdown-menu {
                background: var(--center-channel-bg);
                left: auto;
                position: absolute;
                right: 0;
            }
        }
    }

    #navbar_wrapper {
        .browser--ie & {
            .navbar-default {
                .dropdown-menu {
                    .close {
                        top: 70px;
                    }
                }
            }
        }

        .navbar-default {
            .navbar-header {
                float: none;
                margin: 0 -15px;

                .dropdown__icon {
                    @include background-size(100% 100%);
                    display: inline-block;
                    height: 16px;
                    width: 4px;
                }
            }

            .navbar-toggle {
                display: block;

                &:not(.navbar-right__icon) {
                    &:hover {
                        background: transparent;
                    }
                }
            }

            .spacer {
                flex: 1;
            }

            .Menu {
                @include single-transition(all, .35s, ease);
                background: alpha-color($black, .9);
                display: block;
                height: 100%;
                left: 0;
                overflow: hidden;
                padding: 2.5em 0 0;
                position: fixed;
                top: 48px;
                width: 100%;
                padding-bottom: 50px;

                body.announcement-bar--fixed & {
                    height: calc(100% - 48px - #{$announcement-bar-height});
                    top: calc(48px + #{$announcement-bar-height});
                }
            }

            .Menu__content {
                background: transparent;
                position: absolute;
                top: 0;
                width: 100%;
                max-width: 100%;
                overflow: auto;
                height: 100%;
                max-height: 100%;
                padding: 2rem 2rem 5rem;
            }

            .Menu__close {
                @include border-radius(50%);
                border: 1px solid $white;
                color: $white;
                display: block;
                font-family: 'Open Sans', sans-serif;
                font-size: 23px;
                font-weight: 200;
                height: 30px;
                line-height: 0;
                margin-left: -15px;
                opacity: 1;
                padding-top: 13px;
                position: fixed;
                right: 20px;
                text-align: center;
                text-shadow: none;
                top: 70px;
                width: 30px;
            }

            .dropdown-menu {
                .dropdown__divider {
                    &+.dropdown__divider {
                        display: none;
                    }
                }

                >li {

                    >a,
                    >button {
                        color: $white;
                        font-weight: 600;
                        line-height: 32px;
                        margin: 0 auto;
                        text-align: center;
                        width: 100%;
                        padding: 0.4rem 2rem;
                        height: auto;

                        &:hover {
                            background: transparent;
                        }
                    }

                    .navbar-right__icon {
                        margin: 10px;
                    }

                    hr {
                        border-bottom: 1px solid alpha-color($white, .3);
                        margin: 8px auto;
                    }
                }
            }

            .status {
                margin: 0 8px 0 0;
                top: 2px;
                width: 16px;

                svg {
                    max-height: 20px;
                    width: 16px;
                }
            }
        }
    }

    body {
        &.white {
            .inner-wrap {
                >.row.content {
                    margin-bottom: -185px;
                }
            }
        }
    }

    .footer,
    .footer-pane {
        height: 187px;
    }

    .footer-pane {
        .footer-link {
            display: block;
            line-height: 1.7;
            padding: 0;
            text-align: right;
            width: 100%;

            &.copyright {
                width: 100%;
            }
        }
    }

    .search-bar__container {
        @include flex(0 0 50px);
        background: var(--sidebar-header-bg);
        color: var(--sidebar-header-text-color);
        padding: 0;

        .search-form__container {
            padding: 0 20px 0 0;
        }

        .search__form {
            @include single-transition(all, .2s, linear);
            @include translateX(0);
            background: var(--center-channel-bg);
            border-color: transparent;
            margin-top: 9px;
            width: 100%;

            &:hover,
            &--focused,
            &--focused:hover {
                border-color: transparent;
                border-width: 1px;
            }

            .form-control {
                @include border-radius(3px);
                background: var(--center-channel-bg);
                border: none;
                padding: 0 31px;
            }
        }

        .channel-header__links {
            display: none;
        }
    }

    .sidebar--menu {
        @include single-transition(transform, .35s, ease);
        @include translate3d(290px, 0, 0);
        border: none;
        visibility: hidden;
        width: 290px;

        &.visible {
            display: block;
            visibility: visible;
        }

        &.move--left {
            @include translate3d(0, 0, 0);
            display: block;
            visibility: visible;
        }

        .mentions {
            font-size: 17px;
            font-weight: bold;
        }

        .Menu .MenuItem.MenuItem--with-icon .MenuItem__help-text {
            padding-left: 0;
        }
    }

    .sidebar--left {
        @include single-transition(transform, .35s, ease);
        @include translate3d(-290px, 0, 0);
        border: none;
        width: 290px;

        &.sidebar--padded {
            padding-top: 0;
        }

        &.move--right {
            @include translate3d(0, 0, 0);
        }

        .team__header {
            @include clearfix;
            pointer-events: none;
            padding: 15px;

            .team__name,
            .user__name {
                max-width: 100%;
            }

            .sidebar-header-dropdown {
                display: none;
            }

            .sidebar-header-dropdown__icon {
                display: none;
            }
        }

        .search__form {
            display: block;
        }

        .nav {
            li {
                &.dropdown.open {
                    padding-bottom: 25px;

                    ul {
                        background: var(--center-channel-bg);
                        border-radius: 3px;
                        clear: both;
                        position: relative;
                        top: 5px;

                        li {
                            a {
                                line-height: 30px;
                            }
                        }
                    }
                }

                .nav-more {
                    padding: 12px 0 12px 17px;
                }

                .sidebar-item {
                    height: 45px;

                    &.has-close {
                        .btn-close {
                            @include opacity(.5);
                            display: block;
                            font-size: 25px;
                            margin-right: 4px;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                        }
                    }
                }

                h4 {
                    margin: 1.5em 0 1em;
                }

                >a {
                    font-size: 15px;
                    line-height: 2.5;
                    margin: 0;

                    &:hover,
                    &:focus {
                        background: transparent;
                    }
                }
            }
        }
    }

    #SidebarContainer {
        @include single-transition(transform, .35s, ease);
        @include translate3d(-290px, 0, 0);
        border: none;
        width: 290px;

        +.inner-wrap #app-content {
            margin-left: 0;
        }

        &.move--right {
            @include translate3d(0, 0, 0);
        }

        .team__header {
            @include clearfix;
            pointer-events: none;
            padding: 15px;

            .team__name,
            .user__name {
                max-width: 100%;
            }

            .sidebar-header-dropdown {
                display: none;
            }

            .sidebar-header-dropdown__icon {
                display: none;
            }
        }

        .search__form {
            display: block;
        }

        .SidebarContainer_filterAddChannel {
            width: 100%;
        }

        .SidebarGlobalThreads {
            &,
            .SidebarLink {
                height: 45px;
            }
        }


        .SidebarChannelGroup {
            div {
                &.dropdown.open {
                    padding-bottom: 25px;

                    ul {
                        background: var(--center-channel-bg);
                        border-radius: 3px;
                        clear: both;
                        position: relative;
                        top: 5px;

                        li {
                            a {
                                line-height: 30px;
                            }
                        }
                    }
                }

                .nav-more {
                    padding: 12px 0 12px 17px;
                }

                .SidebarChannel {
                    height: 45px;

                    .SidebarLink {
                        height: 45px;
                        align-items: center;
                        width: 290px;

                        &::before {
                            height: 45px;
                        }

                        .badge + .SidebarMenu {
                            display: none !important;
                        }

                        &:hover .SidebarMenu {
                            display: block !important;
                        }
                    }

                    &.has-close {
                        .btn-close {
                            @include opacity(.5);
                            display: block;
                            font-size: 25px;
                            margin-right: 4px;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                        }
                    }

                    .SidebarMenu_menuButton {
                        margin-top: 1px;
                    }
                }

                h4 {
                    margin: 1.5em 0 1em;
                }

                >a {
                    font-size: 15px;
                    line-height: 2.5;
                    margin: 0;

                    &:hover,
                    &:focus {
                        background: transparent;
                    }
                }
            }
        }

        .SidebarMenu, .AddChannelDropdown {
            display: block !important;

            .Menu {
                top: 0 !important;
            }
        }
    }

    .sidebar--right {
        @include translate3d(100%, 0, 0);
        right: 0;
        width: 100%;

        .browser--ie & {
            -webkit-transform: none !important;
            -moz-transform: none !important;
            -ms-transform: none !important;
            -o-transform: none !important;
            transform: none !important;
            display: none;
        }

        .sidebar--right__bg {
            display: none;
        }

        .sidebar--right__expand {
            display: none;
        }

        .post-create__container {
            form {
                padding: .5em 1em;
            }

            .post-create {
                padding: 0;
            }

            .emoji-rhs {
                // bring back the emoji picker for mobile
                // display: none;
                position: relative;
                right: -1px;
                top: 1px;
            }

            .msg-typing:empty {
                display: none;
            }

            .post-create-footer {
                padding: 6px 0 0;

                .post-error {
                    margin-bottom: 8px;
                }

                .control-label {
                    margin: .5em 0;
                    top: 0;
                }
            }
        }

        .sidebar-collapse__container {
            display: table-cell;
            vertical-align: top;
        }

        .input-clear {
            &.visible {
                visibility: visible;
            }
        }

        .sidebar--right__close {
            display: none;
        }

        .sidebar-right__body {
            height: calc(100% - 56px);
        }
    }

    .search-items-container {
        height: calc(100% - 56px);
    }

    .inner-wrap {
        @include single-transition(all, .35s, ease);

        .app__body & {
            &:before {
                //Some trickery in order for the z-index transition to happen immediately on move-in and delayed on move-out.
                background-color: transparent;
                content: '';
                height: 100%;
                left: -15px;
                position: absolute;
                top: 0;
                transition: background-color .35s ease, z-index 0s ease .35s;
                width: calc(100% + 30px);
                z-index: 0;
            }
        }

        &.move--right {
            @include translate3d(0, 0, 0);

            &:before {
                @include single-transition(all, .35s, ease);

                background-color: rgba(0, 0, 0, .4);
                z-index: 9999;
            }
        }

        &.move--left-small {
            @include translate3d(-290px, 0, 0);

            &:before {
                @include single-transition(all, .35s, ease);
                background-color: rgba(0, 0, 0, .4);
                z-index: 9999;
            }
        }

        &.move--left {
            margin: 0;
            @include translate3d(-100%, 0, 0);
        }
    }

    .integration-option {
        height: auto;
        margin-left: 0;
        width: 100%;
    }

    .app__content {
        margin: 0;
        padding-top: 50px;

        .channel__wrap & {
            padding-top: 50px;
        }

        #channel-header {
            display: none;
        }
    }

    .channel__wrap {
        .row {
            &.header {
                display: block;
            }
        }
    }

    .post-comments {
        padding: 9px 21px 10px 10px !important;
    }

    .multi-teams {

        .app__content {
            margin-left: 0;
        }

        #SidebarContainer+.inner-wrap #app-content {
            margin-left: 0;
        }

        .sidebar--left,
        #SidebarContainer {
            left: 0;

            &.move--right {
                left: 65px;
            }
        }

        .team-sidebar {
            display: none;

            &.move--right {
                display: flex;
                @include translate3d(0, 0, 0);
            }
        }
    }

    .post {
        .attachment {
            .attachment-actions {
                .alert {
                    position: relative;
                    left: 0;
                    top: 0;
                    margin: 1rem 0 0.4rem;
                    display: inline-block;
                }
            }

            .attachment__body__wrap {
                .btn-close {
                    height: 30px;
                    left: -15px;
                    top: 7px;
                    visibility: visible;
                    width: 30px;
                }
            }
        }
    }

    .modal-body {
        .MenuWrapper {
            .Menu {
                .dropdown-menu {
                    right: 20%;
                    top: 35%;
                    left: unset;
                }
            }
        }
    }

    .signup-team-all {
        max-height: calc(75vh - (118px + 187px));
    }

}

@media screen and (max-width: 640px) {
    body {
        &.browser--ie {
            min-width: 600px;
        }
    }

    .section-min .d-flex {
        flex-direction: column;
    }

    .multi-select__help {
        flex-direction: column;

        >span {
            &:first-child {
                margin-bottom: 1rem;
            }
        }
    }

    .filtered-user-list {
        height: calc(100vh - 80px);
    }

    .more-modal--action {
        .filtered-user-list {
            height: calc(100vh - 110px);
        }
    }

    .app__body {
        &.announcement-bar--fixed {
            .modal {
                padding-top: $announcement-bar-height;
            }
        }

        .modal {
            overflow: hidden;
            padding: 0;

            .modal-dialog {
                height: 100%;
                margin: 0;
                max-width: 100%;

                .modal-content {
                    height: 100%;
                }
            }

            .modal-footer {
                bottom: 0;
                position: fixed;
                width: 100%;
            }

            .about-modal {
                .modal-content {
                    display: flex;
                    flex-direction: column;
                }

                .modal-header {
                    flex: 0 0 70px;
                    padding: 0 25px;
                }

                .modal-footer {
                    position: relative;
                }

                .modal-body {
                    max-height: 100%;
                }

                .about-modal__content {
                    display: block;
                }

                .about-modal__hash {
                    p {
                        word-break: break-all;

                        &:first-child {
                            float: none;
                        }
                    }
                }

                .about-modal__logo {
                    float: none;
                    padding: 0;
                    text-align: center;
                    width: 100%;

                    svg {
                        height: 100px;
                        width: 100px;
                    }
                }

                .about-modal__logo+div {
                    padding: 2em 0 0;
                }
            }

            .more-modal {
                .modal-content {
                    display: flex;
                    flex-direction: column;
                }

                .modal-header {
                    flex: 0 0 56px;
                }

                .modal-body {
                    flex: 1 1 auto;
                    max-height: 100%;
                    display: flex;
                }

                .filtered-user-list {
                    flex: 1 1 auto;
                    height: auto;
                }
            }
        }

        .post {
            .open {
                >.dropdown-menu__content {
                    display: table;

                    >.dropdown-menu {
                        display: table-cell;
                    }
                }
            }
        }
    }

    .access-history__table {
        >div {
            display: block;
        }

        .access__report {
            margin: 0 0 15px 15px;
        }

        .access__date {
            div {
                margin-bottom: 15px;
            }
        }
    }

    .activity-log__table {
        >div {
            display: block;
        }

        .activity-log__report {
            width: 100%;
        }

        .activity-log__action {
            margin-top: 10px;
            text-align: left;
        }
    }
}

@media screen and (max-width: 550px) {
    .file-view--single {
        .file__image {
            .image-loaded {
                img {
                    max-width: 100%;
                }
            }
        }
    }

    .post {
        .img-div {
            max-width: 100%;
        }
    }

    .app__body {
        .more-modal {
            &.more-system-members {
                .filter-row {
                    min-height: 80px;
                    width: 100%;
                }
            }

            &.more-direct-channels {
                .member-show {
                    display: none;
                }

                select {
                    margin: 0;
                    width: 100%;
                }
            }
        }
    }

    .member-select__container {
        left: 15px;
        top: 60px;
        width: calc(100% - 30px);
    }
}

@media screen and (max-width: 480px) {
    #user-profile-popover {
        left: 50px !important;
    }

    .emoji-picker__item {
        margin: 0;
        padding-top: calc(100% / #{$emoji-per-row});
        width: calc(100% / #{$emoji-per-row});
    }

    .post {
        .attachment {
            .attachment-actions {
                button {
                    width: 100%;
                }
            }
        }
    }

    .nav-tabs {
        margin-top: 1em;

        >li {
            margin-right: 0;

            a {
                font-size: .9em;
                padding: 6px 11px;
            }
        }
    }

    .sidebar--right {
        .post {
            &.post--compact {
                .post__header {
                    height: auto;
                }
            }
        }
    }

    .backstage-header {
        h1 {
            float: none;
            margin-bottom: 15px;
        }

        .add-integrations-link {
            float: none;
        }
    }

    .app__body {
        .modal {
            .settings-modal {
                &.display--content {
                    .modal-body {
                        max-height: 90%;
                    }
                }

                .modal-body {
                    max-height: 70%;
                    padding-bottom: 0;
                }

                .settings-table {
                    .security-links {
                        display: block;
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .tip-overlay.tip-overlay--sidebar {
        min-height: 350px;
    }

    .member-div {
        padding: 8px 0;

        .member-drop,
        .member-role {
            margin: 0 0 0 44px;
            padding: 5px 0;
            position: relative;
            right: 0;
            top: 0;
        }

        .open>.dropdown-menu {
            left: 0;
            right: auto;
        }
    }

    .sidebar--left,
    #SidebarContainer {
        @include translate3d(-260px, 0, 0);
        width: 260px;
    }

    .inner-wrap {
        &.move--right {
            @include translate3d(0, 0, 0);
        }
    }

    // overides edit modal body element's css position
    // to allow emoji picker to fill the screen on mobile screens < 480
    .edit-modal-body--add-reaction {
        position: static;

        .emoji-picker {
            top: -1px !important;
            left: -1px !important;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }
    }
}

@media screen and (max-height: 640px) {
    .signup-team__container {
        font-size: .9em;
        margin-bottom: 30px;

        .signup-team__name {
            font-size: 2em;
        }
    }
}

@media screen and (max-width: 803px) {
    .signup-team__container {
        .terms-of-service-error__height--fill {
            min-height: calc(100vh - 121px - 4em);
            height: calc(100vh - 121px - 4em);
        }
    }
}

@media screen and (max-width: 449px) {
    .signup-team__container {
        .terms-of-service-error__height--fill {
            min-height: calc(100vh - 138px - 4em);
            height: calc(100vh - 138px - 4em);
        }
    }
}

@media screen and (max-width: 351px) {
    .signup-team__container {
        .terms-of-service-error__height--fill {
            min-height: calc(100vh - 145px - 4em);
            height: calc(100vh - 145px - 4em);
        }
    }
}

@media screen and (max-width: 263px) {
    .signup-team__container {
        .terms-of-service-error__height--fill {
            min-height: calc(100vh - 173px - 4em);
            height: calc(100vh - 173px - 4em);
        }

        .terms-of-service__height--fill {
            min-height: calc(100vh - 100px - 3em);
            height: calc(100vh - 100px - 3em);
        }
    }
}

@media screen and (max-width: 350px) {
    .post-attachment-dropdown {
        &:after {
            left: auto;
            right: 12px;
        }
    }
}

@media screen and (max-width: 320px) {
    .filter-row {
        .Select--multi {
            .Select-multi-value-wrapper {
                display: block;
                max-height: 50px;
                overflow: auto;
            }
        }

        .Select-input {
            >input {
                padding: 8px 0;
            }
        }
    }

    .post {
        &.post--system {
            .post__header {
                padding: 0;
            }
        }

    }

    .multi-teams {
        .sidebar--left,
        #SidebarContainer {
            width: 220px;
        }
    }

    .post {
        .post__header {
            .col__name {
                .user-popover {
                    max-width: 105px;
                }
            }
        }
    }

    .tutorial-steps__container {
        background: v(center-channel-bg);
        left: 0;
        position: fixed;
        top: 0;
        z-index: 9999;

        .tutorial__content {
            .tutorial__steps {
                margin-top: 20px;
                min-height: auto;
                padding: 0 20px;
                width: 100%;

                h1 {
                    font-size: 2em;
                    margin: -5px 0 20px;
                }

                h3 {
                    font-size: 1.5em;
                    margin-bottom: 10px;
                }

                .tutorial__app-icons {
                    margin: 10px 0;
                }

                .tutorial__circles {
                    bottom: auto;
                    margin: 15px 0 20px;
                    position: relative;
                }

                .tutorial__footer {
                    bottom: auto;
                    position: relative;
                }
            }
        }
    }

    .tip-overlay {
        &.tip-overlay--sidebar {
            min-height: 440px;
        }
    }

    .signup-team-all {
        max-height: calc(75vh - (111px + 187px));
    }
}

@media screen and (max-width: 380px) and (max-height: 580px) {
    #navbar_wrapper {
        .navbar-default {
            .dropdown-menu {
                padding-top: 1em;

                >li {
                    >a {
                        border: none;
                        font-size: 13px;
                        line-height: 27px;
                    }
                }
            }
        }
    }
}



// on iOS, allow clicks within an input's label to actually propagate through to the input itself,
// but still allow clicks to a elements to go trough
// http://stackoverflow.com/a/34810294/6325807
label {
    span {
        pointer-events: none;
    }

    span a {
        pointer-events: all;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

    select,
    textarea,
    input {
        font-size: 16px;
    }
}
